<template>
  <div>
    <ul class="mb-10">
      <li
        class="mb-8 md:mb-10 flex text-sm md:text-lg xl:text-xl"
        v-for="item in props.list"
        :key="item.id"
        :class="{ 'md:hidden': item.id > 4 }"
      >
        <div
          class="mt-1.5 min-w-[0.5rem] min-h-[0.5rem] w-2 h-2 border rounded-full mr-4 border-[#D4026E]"
        ></div>

        <span>{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  list: Array,
});
</script>

<style lang=""></style>
